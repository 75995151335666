import { useMutation, useQuery } from '@apollo/client';
import { getPhysiotecProgram } from 'api/exercises/exercises.api';
import { Exercise } from 'api/exercises/exercises.model';
import { CREATE_GOAL_HISTORY, DELETE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_GOAL } from 'api/goal/goal.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as ExerciseIcon } from 'assets/vectors/fi-rr-exercise.svg';
import { format } from 'date-fns';
import { useTitle } from 'hooks/useTitle';
import isEmpty from 'lodash/isEmpty';
import { useExerciseChart } from 'pages/home/hooks/useExerciseChart';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery as useReactQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { DATE_FORMAT } from 'utils/helpers';
import { WeeklyReportProps, generateWeeklyExerciseTables, getCurrentWeek } from 'utils/helpers/exercises';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Header } from 'components/layout/BaseLayout/Header';
import Accordion2 from 'components/ui/Accordion/Accordion2';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './ExercisesList.styles';
import { ExerciseCard } from './components/ExerciseCard';
import { ExercisesLoading } from './components/ExercisesLoading';

export const ExercisesList: React.FC = () => {
  const [currentId, setCurrentId] = useState<string>('');
  const [completedThisWeek, setCompletedThisWeek] = useState<number>(0);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.exercises' });
  const navigate = useNavigate();
  const { goalId } = useParams();
  const { data: currentUserData, loading: userLoading } = useQuery(GET_CURRENT_USER_DATA);

  const { data: goalData, loading: isGoalLoading } = useQuery(GET_GOAL, {
    variables: { id: goalId, date: format(Date.now(), 'yyyy-MM-dd') },
    fetchPolicy: 'network-only',
  });

  const { userChart, createUserChart, userChartLoading, updateUserChart, updateChartLoading } = useExerciseChart(
    currentUserData?.currentUser?.id || '',
  );

  const programId = goalData?.goal?.description?.split(',')[0]; // Splitting it by comma. Allow any test after comma in healthie

  const {
    data: programData,
    isLoading,
    isIdle,
  } = useReactQuery('getPhysiotecProgram', () => getPhysiotecProgram({ programId }), {
    enabled: !!programId,
    cacheTime: 300000, // 5mins
  });

  const program = programData?.data?.program_details;
  const pdfLink = programData?.data?.pdf_link;
  const exercises = program?.exercise ? Object.values(program?.exercise) : [];

  const [createGoalHistory] = useMutation(CREATE_GOAL_HISTORY);
  const [deleteGoalHistory] = useMutation(DELETE_GOAL_HISTORY, { fetchPolicy: 'no-cache' });

  const title = useTitle({ isLoading: isGoalLoading, repeat: goalData?.goal?.repeat });

  useEffect(() => {
    if (userChart && userChart?.formAnswerGroups?.length === 0 && !userChartLoading) {
      createUserChart();
    }
    // Do not add any other dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChart, userChartLoading]);

  const currentWeek = getCurrentWeek();

  useEffect(() => {
    const chart = userChart?.formAnswerGroups?.[0];
    const exerciseJson = chart?.form_answers?.find((exercise) => exercise.label === 'Exercise JSON');
    const exerciseText = chart?.form_answers?.find((exercise) => exercise.label === 'Exercises');
    let chartExercises: WeeklyReportProps[] = JSON.parse(
      exerciseJson?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '[]',
    );

    const updateChart = async () => {
      const exerciseTableHTML = generateWeeklyExerciseTables(chartExercises);

      await updateUserChart({
        form_id: chart?.id || '',
        formAnswers: [
          {
            answer: `<p style="display:none;">${JSON.stringify(chartExercises)}</p>`,
            custom_module_id: exerciseJson?.custom_module?.id || '',
            id: exerciseJson?.id || '',
            user_id: currentUserData?.currentUser?.id || '',
          },
          {
            answer: exerciseTableHTML,
            custom_module_id: exerciseText?.custom_module?.id || '',
            id: exerciseText?.id || '',
            user_id: currentUserData?.currentUser?.id || '',
          },
        ],
      });
    };
    const thisWeek = chartExercises.find((ex) => ex.startDate === currentWeek.start);

    if (chart) {
      if (!userLoading && chart && currentWeek && chartExercises.length === 0) {
        chartExercises = [
          {
            startDate: currentWeek.start,
            endDate: currentWeek.end,
            exerciseData: exercises.map((exercise) => {
              const tags = [
                exercise.duration,
                exercise.frequency,
                exercise.hold,
                exercise.repetition,
                exercise.rest,
                exercise.sets,
                exercise.tempo,
                exercise.weight,
              ]
                .filter((t) => t)
                .join(', ');

              return {
                execise_id: exercise.code,
                completed: false,
                tags: `${tags}`,
                title: exercise.title,
                dateCompleted: '',
              };
            }),
          },
        ];

        updateChart();
      } else if (!isGoalLoading && !userLoading && !updateChartLoading && !userChartLoading && !thisWeek) {
        (chartExercises = [
          ...chartExercises,
          {
            startDate: currentWeek.start,
            endDate: currentWeek.end,
            exerciseData: exercises.map((ex) => {
              const tags = [ex.duration, ex.frequency, ex.hold, ex.repetition, ex.rest, ex.sets, ex.tempo, ex.weight]
                .filter((t) => t)
                .join(', ');

              return {
                execise_id: ex.code,
                completed: false,
                tags: tags,
                title: ex.title,
                dateCompleted: '',
              };
            }),
          },
        ]),
          updateChart();
      } else if ((thisWeek?.exerciseData?.length || 0) < exercises.length) {
        const newExercises = exercises.filter(
          (ex) => thisWeek?.exerciseData.find((exData) => exData.execise_id === ex.code) === undefined,
        );

        thisWeek?.exerciseData.push(
          ...newExercises.map((ex) => {
            const tags = [ex.duration, ex.frequency, ex.hold, ex.repetition, ex.rest, ex.sets, ex.tempo, ex.weight]
              .filter((t) => t)
              .join(', ');

            return {
              execise_id: ex.code,
              completed: false,
              tags: `${tags}`,
              title: ex.title,
              dateCompleted: '',
            };
          }),
        );
        updateChart();
      }
    }

    // Do not add any other dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading, updateUserChart, userChart, userChartLoading]);

  useEffect(() => {
    if (userChart && programData) {
      const currentWeek = getCurrentWeek();
      const chart = userChart?.formAnswerGroups?.[0];
      const exerciseJson = chart?.form_answers?.find((exercise) => exercise.label === 'Exercise JSON');
      const exercises: WeeklyReportProps[] = JSON.parse(exerciseJson?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '[]');
      const thisWeek = exercises.find((ex) => ex.startDate === currentWeek.start);

      const program = programData?.data?.program_details;
      const programExercises = (program?.exercise ? Object.values(program?.exercise) : []).reduce((acc, curr) => {
        acc[curr.code] = curr;
        return acc;
      }, {} as Record<string, Exercise>);

      const completedExercises =
        thisWeek?.exerciseData.filter((ex) => ex.completed === true && !isEmpty(programExercises[ex.execise_id]))
          .length || 0;
      setCompletedThisWeek(completedExercises);
    }
  }, [userChart, programData]);

  useEffect(() => {
    const markAllAsCompleted = async () => {
      try {
        Mixpanel.track('Exercise_Goal');
        await createGoalHistory({
          variables: {
            user_id: currentUserData?.currentUser?.id,
            goal_id: goalId,
            completed_on: format(Date.now(), DATE_FORMAT),
          },
        });
      } catch (error) {
        throw new Error(`Completing exercises failed: ${error}`);
      }
    };
    if (userChart) {
      const chart = userChart?.formAnswerGroups?.[0];
      const exerciseJson = chart?.form_answers?.find((exercise) => exercise.label === 'Exercise JSON');
      const chartExercises: WeeklyReportProps[] = JSON.parse(
        exerciseJson?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '[]',
      );
      const thisWeek = chartExercises.find((ex) => ex.startDate === currentWeek.start);

      const completed = thisWeek?.exerciseData.filter((ex) => ex.completed === true).length || 0;

      if (completed === exercises.length) {
        markAllAsCompleted();
      } else {
        deleteGoalHistory({
          variables: {
            id: goalId,
            completed_on: format(Date.now(), DATE_FORMAT),
          },
        });
      }
    }
    // Do not add any other dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChart, isLoading]);

  useEffect(() => {
    if (goalData?.goal) {
      // setIsCompleted(isGoalCompleted(goalData.goal));
    }
  }, [goalData]);

  if (isIdle || isLoading || isGoalLoading) {
    return <ExercisesLoading />;
  }

  const isCompleted = (id: string) => {
    const chart = userChart?.formAnswerGroups?.[0];

    if (!chart) {
      return false;
    }
    const exerciseJson = chart.form_answers?.find((exercise) => exercise.label === 'Exercise JSON');
    if (!exerciseJson) {
      return false;
    }

    const chartExercise: WeeklyReportProps[] = JSON.parse(
      exerciseJson.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '[]',
    );
    if (!exercises) {
      return false;
    }
    const thisWeek = chartExercise.find((ex) => ex.startDate === currentWeek.start);

    if (!thisWeek) {
      return false;
    }

    return thisWeek.exerciseData.find((ex) => ex.execise_id === id)?.completed ?? false;
  };

  const handleAccordionCheck = async (exercise: Exercise) => {
    setCurrentId(exercise.code);
    const chart = userChart?.formAnswerGroups?.[0];
    if (!chart) {
      return false;
    }
    const exerciseJson = chart.form_answers?.find((exercise) => exercise.label === 'Exercise JSON');
    const exerciseText = chart.form_answers?.find((exercise) => exercise.label === 'Exercises');

    if (!exerciseJson) {
      return false;
    }

    const weeklyExercises: WeeklyReportProps[] = JSON.parse(
      exerciseJson.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '[]',
    );
    // if (exercises.length === 0) {
    //   exercises = [{
    //     startDate: currentWeek.date,
    //     endDate: currentWeek.end,
    //     exerciseData: [{
    //       execise_id: exercise.code,
    //       completed: true,
    //       title: exercise.title,
    //       dateCompleted: new Date().toISOString(),
    //     }]
    //   }]
    // }

    const thisWeek = weeklyExercises.find((ex) => ex.startDate === currentWeek.start);

    if (!thisWeek) {
      return false;
    }

    const exerciseIndex = thisWeek.exerciseData.findIndex((ex) => ex.execise_id === exercise.code);

    if (exerciseIndex === -1) {
      const tags = [
        exercise.duration,
        exercise.frequency,
        exercise.hold,
        exercise.repetition,
        exercise.rest,
        exercise.sets,
        exercise.tempo,
        exercise.weight,
      ]
        .filter((t) => t)
        .join(', ');
      thisWeek.exerciseData.push({
        execise_id: exercise.code,
        completed: true,
        tags: `${tags}`,
        title: exercise.title,
        dateCompleted: new Date().toISOString(),
      });
    } else {
      thisWeek.exerciseData[exerciseIndex].completed = !thisWeek.exerciseData[exerciseIndex].completed;
      thisWeek.exerciseData[exerciseIndex].dateCompleted = new Date().toISOString();
    }

    const exerciseTableHTML = generateWeeklyExerciseTables(weeklyExercises);

    const res = await updateUserChart({
      form_id: chart.id,
      formAnswers: [
        {
          answer: `<p style="display:none;">${JSON.stringify(weeklyExercises)}</p>`,
          custom_module_id: exerciseJson.custom_module?.id || '',
          id: exerciseJson.id,
          user_id: currentUserData?.currentUser?.id || '',
        },
        {
          answer: exerciseTableHTML,
          custom_module_id: exerciseText?.custom_module?.id || '',
          id: exerciseText?.id || '',
          user_id: currentUserData?.currentUser?.id || '',
        },
      ],
    });

    if (res.data?.updateFormAnswerGroup?.form_answer_group) {
      return true;
    }

    return false;
  };

  return (
    <BaseLayout
      header={
        <Styled.HeaderWrapper>
          <Header onBackClick={() => navigate(paths.homePage)} variant="back" title={title} />
        </Styled.HeaderWrapper>
      }
      footer={null}
      contentContainerStyles={{ padding: 0 }}
    >
      <Styled.Content>
        {exercises.length > 0 ? (
          <>
            <Styled.ContentHeader
              label={goalData?.goal?.name}
              progress={(completedThisWeek / exercises.length) * 100}
              description={`${completedThisWeek}/${exercises.length} ${t('exercisesCompleted')}`}
              icon={<Icon element={ExerciseIcon} size={20} aria-hidden />}
              button={
                <Button
                  size="sm"
                  variant="secondary-transparent"
                  style={{ padding: '7px 14px' }}
                  onClick={() => {
                    Mixpanel.track('Exercise_PDF_downloads');
                    window.open(pdfLink, '_blank')?.focus();
                  }}
                >
                  {t('print')}
                </Button>
              }
            />
            <Typography.Div weight={600} style={{ margin: '20px 20px 0 30px' }}>
              {t('checkOff')}:
            </Typography.Div>
            <Styled.ExerciseItems>
              {exercises.map((exercise: Exercise, index) => (
                <Styled.ExerciseProgramWrapper key={exercise.code}>
                  <Accordion2
                    key={exercise.code}
                    label={`Exercise ${index + 1}`}
                    title={exercise.short_title}
                    previewText={[
                      exercise.duration,
                      exercise.frequency,
                      exercise.hold,
                      exercise.repetition,
                      exercise.rest,
                      exercise.sets,
                      exercise.tempo,
                      exercise.weight,
                    ]
                      .filter((t) => t)
                      .join(', ')}
                    checkBoxLoading={updateChartLoading && currentId === exercise.code}
                    checked={isCompleted(exercise.code)}
                    onChange={() => handleAccordionCheck(exercise)}
                    updateChartLoading={updateChartLoading}
                  >
                    <ExerciseCard key={exercise.code} exerciseNumber={index + 1} exercise={exercise} />
                  </Accordion2>
                </Styled.ExerciseProgramWrapper>
              ))}
            </Styled.ExerciseItems>
          </>
        ) : (
          <Styled.ContactWithSupport>{t('contactWithSupport')}</Styled.ContactWithSupport>
        )}
      </Styled.Content>

      {/* {exercises.length > 0 && (
        <Styled.MarkAsCompletedWrapper>
          {isCompleted ? (
            <ActionCompleted label={`${t('completed')}!`} />
          ) : (
            <Button onClick={markAllAsCompleted}>{t('markAllAsCompleted')}</Button>
          )}
        </Styled.MarkAsCompletedWrapper>
      )} */}

      <Styled.MarkAsCompletedArea />
    </BaseLayout>
  );
};
